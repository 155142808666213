import type { FC } from 'react'

import { GlobeIconStyled } from '../../styles'
import ClassicView from '../ClassicView'

type Props = {
  variant?: 'classic' | 'default'
}

export const MenuContainer: FC<Props> = ({ variant }) => {
  return <>{variant === 'classic' ? <ClassicView /> : <GlobeIconStyled />}</>
}
